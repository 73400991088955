<template>
  <section class="py-6 py-lg-8">
    <div class="container">
      <div class="px-lg-8">
        <h2 class="content-title fs-1 text-center mb-8">重大訊息公告</h2>
        <p class="text_indent">
          本公司為上櫃公司，依主管機關金管會證期局規定定期對投資大眾公開財務資訊，請直接連結至公開資訊觀測站
          <a class="new__link" href="http://mops.twse.com.tw/">http://mops.twse.com.tw/</a
          >，輸入股票代號 7767，即可取得本公司相關資料。
        </p>
        <p>&nbsp;</p>
        <p>股票代號：7767</p>
        <p>股查詢路徑：公開資訊觀測站 / 重大訊息與公告 / 歷史重大訊息-本國及第一上市(櫃)公司</p>
        <p>
          查詢網址：
          <a class="new__link" href="https://mops.twse.com.tw/mops/web/t05st01"
            >https://mops.twse.com.tw/mops/web/t05st01</a
          ><br />
          (公司代號 7767 → 年度 → 月份 → 搜尋)
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.new__link {
  text-decoration: underline;
}
.text_indent {
  text-indent: 2rem;
}
p {
  font-weight: 300;
}
</style>
